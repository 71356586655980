import { createContext, useEffect } from "react";
import Cookies from "js-cookie";
import React from "react";
import { showAlert } from "./Helpers";
import { UiHelper } from "./UiHelper";

//export class UserTypeNames {
//    static get User(): string { return "User"; }
//    static get Respondent(): string { return "Respondent"; }
//}

interface IEntity {
    token: string;
}

export interface IResponseBase {
    hasErrors: boolean;
    errors: string[];
}

export interface INullResponse extends IResponseBase {
}

export interface IScalarResponse<T> extends IResponseBase {
    value: T;
}

export interface ISingletonDtoResponse<T> extends IResponseBase {
    item: T;
}

export interface IEnumValue {
    name: string;
    value: string;
}

export class QuestionTypeNames {
    static get Text() { return "Text" };
    static get MultipleChoice() { return "MultipleChoice" };
    static get InspectionState() { return "InspectionState" };
    static get PracticalFault() { return "PracticalFault" };
    static get TrainingCategory() { return "TrainingCategory" };
}

export interface IQuestion extends IEntity {
    number: string;
    name: string;
    type: string;
    penaltyPoints: number;
    options: IQuestionOption[];
}

export interface IQuestionOption extends IEntity {
    number: string;
    name: string;
}

export interface IQuestionGroup extends IEntity {
    name: string;
    questions: IQuestion[];
}

export enum QuestionMark {
    NotMarked = 0,
    Pass = 1,
    Fail = 2
}

export enum WebUserType {
    Respondent = "Respondent",
    User = "User"
}

export enum TrainingNeeds {
    FullTrainingRequired = "FullTrainingRequired",
    ReducedTrainingSufficient = "ReducedTrainingSufficient",
    NoTrainingRequired = "NoTrainingRequired"
}

export enum QuestionSetType {
    TheoryTest,
    PracticalTest,
    OperatorProfile,
    Inspection,
    TrainingRecord
}

export class QuestionSetTypeNames {
    static get TheoryTest() { return "TheoryTest" };
    static get PracticalTest() { return "PracticalTest" };
    static get OperatorProfile() { return "OperatorProfile" };
    static get Inspection() { return "Inspection" };
    static get TrainingRecord() { return "TrainingRecord" };
}

export enum QuestionAnswerMode {
    ReadOnly = 0,
    ReadWrite = 1,
    Mark = 2
}

export class QuestionAnswerModeNames {
}

export interface IQuestionSetVersion extends IEntity {
    name: string;
    version: number;
    groups: IQuestionGroup[];
}

export interface IAnswerSet extends IEntity {
    version: IQuestionSetVersion;
    answers: IAnswerItem[];
    type: string;
    mode: string;
}

export interface IAnswerItem {
    questionToken: string; 
    answer: string;
    mark: string;
    acknowledgement: IAnswerAcknowledgement;
}

export interface IAnswerAcknowledgement {
    hasRespondentAcknowledgement: boolean;
    hasInstructorAcknowledgement: boolean;
    isNotApplicable: boolean;
}

export interface IAnswerKeyItem {
    token: string;
    answer: string;
}

export interface IExamFlow extends IEntity {
    name: string;
    course: ICourse;
    reservation: IReservation;
    instructor: IShortUser;
    instructorRenderedAssistance: boolean;
}

export interface IReservation extends IEntity {
    course: ICourse;
    dates: IReservationDate[];
    datesAsString: string;
    numRespondentsExpected: number;
}

export interface IReservationDate extends IEntity {
    dateAsString: string;
    startTimeAsString: string;
    endTimeAsString: string;
}

export interface ICourse extends IEntity {
    name: string;
}

export interface IWebSession {
    token: string;
    workspaceToken: string;
    isAuthenticated: any;
    user: IShortUser;
    userType: WebUserType;
    hasWorkspaces: boolean;
}

export interface IShortUser {
    token: string;
    type: string;
    fullName: string;
}

export interface ILoginUserRequest {
    email: string;
    password: string;
    rememberMe: boolean;
}

export interface IWorkspace extends IEntity {
    name: string;
    referenceNumber: string;
}

export interface IGetWorkspacesResponse {
    examFlowToken(examFlowToken: any): any;
    items: IWorkspace[];
    currentWorkspace: IWorkspace;
}

export interface ILearningOutcome {
    name: string;
    bullets: string[];
    instructorNotes: string[];
}

export interface ICourseObjectives {
    details: string[];
}

export interface IInspectionVehicleDetails {
    registration: string;
    make: string;
    model: string;
    variations: string;
    attachments: string;
    other: string;
}

export interface IPrePracticalAssessmentDetails {
    status: string;
    notes: string;
}

export interface ICourseResults {
    theoryTestResults: ITheoryTestResults;
    inspectionResults: IInspectionResults;
    practicalTestResults: IPracticalTestResults;
    majorFailureReasons: string[];
}

export interface ICourseResultsItem {
    numQuestions: number;
    numNotAnswered: number;
}

export interface ITheoryTestResults extends ICourseResultsItem {
    numPassed: number;
    numFailed: number;
    hasScore: boolean;
    score: number;
    passFail: string;
}

export interface IInspectionResults extends ICourseResultsItem {
    numNotApplicable: number;
    numPassed: number;
    numFailed: number;
    overallMark: string;
}

export interface IPracticalTestResults extends ICourseResultsItem {
    score: number;
    practicalTestThreshold: number;
    passFail: string;
    majorFailureReasons: IEnumValue[];
}

export interface IShortQuestionWithNotes extends IEntity {
    notes: string[];
}

export interface IRespondent extends IEntity {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    hasDateOfBirth: boolean;
    machineType: string;
    detailsOfPreviousTraining: string;
    healthAndSafetyPassed: boolean;
    healthAndSafetyPassedDate: string;
    hasHealthAndSafetyPassedDate: boolean;
    trainingNeeds: TrainingNeeds;
    fullDurationBasicTrainingReasons: string;
    courseDurationReducedReasons: string;
    trainingRequirementDays: number;
    hasRespondentSignedOff: boolean;
    hasInstructorSignedOff: boolean;
    referenceNumber: string;
}

export interface IEmploymentDetail extends IEntity {
    name: string;
    dates: string;
    details: string;
}

export interface ITrainingCategoryAcknowledgement {
    operator: boolean,
    instructor: boolean
}

export interface IAddEmploymentDetailsRequest {
    name: string;
    dates: string;
    details: string;
}

export interface IBookings {
    items: IBookingItem[];
}

export interface IBookingItem {
    reservation: IReservation;
    respondents: IRespondent[];
}

export interface IInductOperatorArgs {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    operatorNumber: string;
}

export interface IRespondentPhotoResponse {
    data: string;
    hasData: boolean;
}

class Busy {
    private static _instance: Busy = new Busy();

    static get instance(): Busy {
        return Busy._instance;
    }

    start(): Busy {
        return Busy._instance;
    }

    complete(busy: Busy) {
    }
}

const SessionKey = "__nporsSession";

export function useSession(callback: (session: IWebSession) => void): void {
    var session = React.useContext(SessionContext);

    useApi(async (api) => {
        if (session == null) {
            session = await api.getSession();
        }
        callback(session);
    });
}

function setSessionCookie(token: string) {
    Cookies.set(SessionKey, token, { expires: 7 });
}

export function useApi(callback: (api: Api) => void): void {
    //const [session, setSession] = useState<IWebSession>();

    const session = React.useContext(SessionContext);
    useEffect(() => {

        var run = async () => {
            var api = new Api();

            if (session == null) {
                var theSession = await api.getSession();
                console.log("session --> ", theSession.token);
                //Cookies.set(SessionKey, theSession.token, { expires: 7 });
                //setSession(theSession);
                setSessionCookie(theSession.token);
            }

            callback(api);
        };

        run();

    }, []);
}

export interface ICallApiArgs {
    errors?: (errors: string[]) => void;
}

export const SessionContext = createContext<IWebSession | null>(null);

function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64data = reader.result;
            if (typeof base64data === 'string') {
                resolve(base64data);
            } else {
                reject('Failed to convert Blob to Base64');
            }
        };
        reader.onerror = () => {
            reject("Error reading Blob as Base64");
        };
        reader.readAsDataURL(blob); // This will encode the Blob as a Base64 string
    });
}

export class ServerHost {
    uri: string;

    constructor(devPort: number) {
        let host = document.location.host;

        let index = host.lastIndexOf(":");
        if (index != -1)
            host = host.substring(0, index);

        index = host.indexOf(".");
        let subdomain = host.substring(0, index);
        const domain = host.substring(index + 1);

        let uri = "";
        if (host === "localhost")
            uri = "http://localhost:" + devPort;
        else {
            index = subdomain.lastIndexOf("-");
            let env = "";
            if (index !== -1) {
                env = subdomain.substring(index + 1);
                subdomain = subdomain.substring(0, index);

                var thePort = "";
                if (env.toLowerCase() === "dev")
                    thePort = ":" + devPort;

                uri = "https://" + subdomain + "-api-" + env + "." + domain + thePort;
            } else
                uri = "https://" + subdomain + "-api." + domain;
        }

        if (!(uri.endsWith("/")))
            uri = uri + "/";
        this.uri = uri;
    }
}

class Api {
    baseUri: string = "";

    constructor() {
        this.baseUri = new ServerHost(5271).uri;
        console.log("API base --> ", this.baseUri);
    }

    getServiceUrl(uri: string): string {
        while (uri.startsWith("/"))
            uri = uri.substring(1);
        return this.baseUri + uri;
    }

    private async callApiAsync(uri: string, method: string, data: any, callArgs?: ICallApiArgs): Promise<any> {
        if (callArgs == null)
            callArgs = {};

        var busy = Busy.instance.start();
        var url = this.getServiceUrl(uri);
        var result = await ($ as any).ajax({
            url: url,
            method: method,
            data: JSON.stringify(data),
            dataType: "json",
            contentType: "application/json",
            headers: {
                "x-npors-session": Cookies.get(SessionKey)
            },
            error: (jqXhr: any, status: any, error: string) => {
                const responseBody = JSON.parse(jqXhr.responseText);

                let message = "A server error occurred.";
                if (responseBody.token != null && responseBody.token.length > 0)
                    message += "\r\n\r\nSupport ID: " + responseBody.token;

                UiHelper.showAlert(message);
                return;
            },
            complete: () => {
                Busy.instance.complete(busy);
            }
        });

        if (result == null || !(result.hasErrors)) {
            return result;
        } else {
            if (callArgs.errors != null)
                callArgs.errors(result.errors);
            else
                showAlert(result.errors);
            return result;
        }
    }

    private getAsync(uri: string, callArgs?: ICallApiArgs): Promise<any> {
        return this.callApiAsync(uri, "GET", null, callArgs);
    }

    private postAsync(uri: string, args: any, callArgs?: ICallApiArgs): Promise<any> {
        return this.callApiAsync(uri, "POST", args, callArgs);
    }

    private putAsync(uri: string, args: any, callArgs?: ICallApiArgs): Promise<any> {
        return this.callApiAsync(uri, "PUT", args, callArgs);
    }

    private deleteAsync(uri: string, callArgs?: ICallApiArgs): Promise<void> {
        return this.callApiAsync(uri, "DELETE", null, callArgs);
    }

    getQuestionGroupAsync(token: string): Promise<IQuestionGroup> {
        return this.getAsync("/questiongroups/" + encodeURIComponent(token));
    }

    getAnswerSetAsync(token: string): Promise<IAnswerSet> {
        return this.getAsync("/examflows/answersets/" + encodeURIComponent(token));
    }

    setAnswersAsync(answerSet: IAnswerSet, questionGroup: IQuestionGroup, answers: { [key: string]: string }) {
        return this.postAsync("/examflows/answersets/" + encodeURIComponent(answerSet.token) + "/questiongroups/" + encodeURIComponent(questionGroup.token), {
            answers: answers
        });
    }

    getSession(): Promise<IWebSession> {
        return this.getAsync("/application/websession");
    }

    logoutAsync(): Promise<INullResponse> {
        return this.getAsync("/application/logout");
    }

    loginUserAsync(args: ILoginUserRequest): Promise<INullResponse> {
        return this.postAsync("/application/login-user", args);
    }

    impersonateUserAsync(token: string): Promise<INullResponse> {
        return this.postAsync("/application/impersonate-user", {
            value: token
        });
    }

    loginRespondentAsync(args: ILoginUserRequest): Promise<INullResponse> {
        return this.postAsync("/application/login-respondent", args);
    }

    getWorkspacesAsync(): Promise<IGetWorkspacesResponse> {
        return this.getAsync("/workspaces");
    }

    touchAndGetWorkspacesAsync(): Promise<IGetWorkspacesResponse> {
        return this.getAsync("/workspaces/touch");
    }

    setCurrentWorkspaceAsync(item: IWorkspace): Promise<INullResponse> {
        return this.putAsync("/workspaces/current", {
            value: item.token
        });
    }

    getCurrentWorkspaceAsync(): Promise<IWorkspace> {
        return this.getAsync("/workspaces/current");
    }

    getLearningOutcomesForCurrentWorkspaceAsync(): Promise<ILearningOutcome[]> {
        return this.getAsync("/workspaces/current/learning-outcomes");
    }

    getCourseObjectivesForCurrentWorkspaceAsync(): Promise<ICourseObjectives> {
        return this.getAsync("/workspaces/current/course-objectives");
    }

    getVehicleDetailsForCurrentWorkspaceAsync(): Promise<IInspectionVehicleDetails> {
        return this.getAsync("/workspaces/current/inspection-vehicle");
    }

    setVehicleDetailsFieldForCurrentWorkspaceAsync(name: string, value: any): Promise<INullResponse> {
        return this.putAsync("/workspaces/current/inspection-vehicle/" + encodeURIComponent(name), {
            value: value
        });
    }

    setPrePracticalAssessmentFieldForCurrentWorkspaceAsync(name: string, value: any): Promise<INullResponse> {
        return this.putAsync("/workspaces/current/pre-practical-assessment/" + encodeURIComponent(name), {
            value: value
        });
    }

    getPrePracticalAssessmentForCurrentWorkspaceAsync(): Promise<IPrePracticalAssessmentDetails> {
        return this.getAsync("/workspaces/current/pre-practical-assessment");
    }

    getAnswerSetForCurrentWorkspaceAsync(type: QuestionSetType): Promise<IAnswerSet> {
        return this.getAsync("/workspaces/current/answersets/bytype/" + QuestionSetType[type]);
    }

    getAnswerKeyForCurrentWorkspaceAsync(type: QuestionSetType): Promise<IAnswerKeyItem[]> {
        return this.getAsync("/workspaces/current/answersets/bytype/" + QuestionSetType[type] + "/answerkey");
    }

    setAnswerForCurrentWorkspaceAsync(answerSet: IAnswerSet, question: IQuestion, value: any): Promise<INullResponse> {
        return this.putAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token ) + "/questions/" + encodeURIComponent(question.token) + "/answer", {
            value: String(value)
        });
    }

    getInspectionStatesAsync(): Promise<IEnumValue[]> {
        return this.getAsync("/application/inspection-states");
    }

    getTrainingNeedsAsync(): Promise<IEnumValue[]> {
        return this.getAsync("/application/training-needs");
    }

    getMajorFailureReasonsAsync(): Promise<IEnumValue[]> {
        return this.getAsync("/application/major-failure-reasons");
    }

    getExamFlowForCurrentWorkspaceAsync(): Promise<IExamFlow> {
        return this.getAsync("/workspaces/current/exam-flow");
    }

    markAnswerAsync(answerSet: IAnswerSet, question: IQuestion, mark: QuestionMark): Promise<INullResponse> {
        return this.putAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/questions/" + encodeURIComponent(question.token) + "/mark", {
            value: QuestionMark[mark]
        });
    }

    getResultsForCurrentWorkspaceAsync(): Promise<ICourseResults> {
        return this.getAsync("/workspaces/current/results");
    }

    canFinishAnswerSetForCurrentWorkspaceAsync(answerSet: IAnswerSet): Promise<IScalarResponse<boolean>> {
        return this.getAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/canfinish");
    }

    finishAnswerSetForCurrentWorkspaceAsync(answerSet: IAnswerSet): Promise<INullResponse> {
        return this.getAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/finish");
    }

    getQuestionNotesForAnswerSetAsync(answerSet: IAnswerSet): Promise<IShortQuestionWithNotes[]> {
        return this.getAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/questionnotes");
    }

    addPracticalExamFaultForCurrentWorkspaceAsync(answerSet: IAnswerSet, question: IQuestion): Promise<IScalarResponse<number>> {
        return this.getAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/questions/" + encodeURIComponent(question.token) + "/addfault");
    }

    removePracticalExamFaultForCurrentWorkspaceAsync(answerSet: IAnswerSet, question: IQuestion): Promise<IScalarResponse<number>> {
        return this.getAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/questions/" + encodeURIComponent(question.token) + "/removefault");
    }

    getEmploymentDetailsForCurrentWorkspaceAsync(): Promise<IEmploymentDetail[]> {
        return this.getAsync("/workspaces/current/employment-details");
    }

    addEmploymentDetailsToCurrentWorkspaceAsync(request: IAddEmploymentDetailsRequest): Promise<INullResponse> {
        return this.postAsync("/workspaces/current/employment-details/add", request);
    }

    deleteEmploymentDetailsToCurrentWorkspaceAsync(item: IEmploymentDetail): Promise<void> {
        return this.deleteAsync("/workspaces/current/employment-details/" + encodeURIComponent(item.token));
    }

    getRepondentForCurrentWorkspaceAsync(): Promise<IRespondent> {
        return this.getAsync("/workspaces/current/respondent");
    }

    getRespondentPhotoAsync(): Promise<IRespondentPhotoResponse> {
        return this.getAsync("/workspaces/current/respondent/photo");
    }

    updateRespondentForCurrentWorkspaceAsync(item: IRespondent, ignoreErrors: boolean): Promise<INullResponse> {
        const callArgs: ICallApiArgs = {
        };

        if (ignoreErrors)
            callArgs.errors = (errors) => { };

        return this.putAsync("/workspaces/current/respondent", {
            item: item,
            doValidation: !(ignoreErrors)

        }, callArgs);
    }

    signOffRespondentAsync(): Promise<INullResponse> {
        return this.getAsync("/workspaces/current/respondent/signoff");
    }

    signOffInstructorAsync(): Promise<INullResponse> {
        return this.getAsync("/workspaces/current/instructor/signoff");
    }

    acknowledgeAnswerAsync(answerSet: IAnswerSet, question: IQuestion): Promise<IAnswerAcknowledgement> {
        return this.getAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/questions/" + encodeURIComponent(question.token) + "/acknowledge");
    }

    setAnswerAsNotApplicableAsync(answerSet: IAnswerSet, question: IQuestion): Promise<IAnswerAcknowledgement> {
        return this.getAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/questions/" + encodeURIComponent(question.token) + "/setasnotapplicable");
    }

    setAnswerAsApplicableAsync(answerSet: IAnswerSet, question: IQuestion): Promise<IAnswerAcknowledgement> {
        return this.getAsync("/workspaces/current/answersets/" + encodeURIComponent(answerSet.token) + "/questions/" + encodeURIComponent(question.token) + "/setasapplicable");
    }

    getMajorFailureReasonsForWorkspaceAsync(): Promise<string[]> {
        return this.getAsync("/workspaces/current/majorfailurereasons");
    }

    setMajorFailureReasonsForWorkspaceAsync(reasons: string[]): Promise<INullResponse> {
        return this.putAsync("/workspaces/current/majorfailurereasons", {
            reasons: reasons
        });
    }

    getBookings(): Promise<IBookings> {
        return this.getAsync("/reservations/bookings");
    }

    getReservation(token: string): Promise<IReservation> {
        return this.getAsync("/reservations/" + encodeURIComponent(token));
    }

    inductOperator(item: IReservation, args: IInductOperatorArgs): Promise<ISingletonDtoResponse<IExamFlow>> {
        return this.postAsync("/reservations/" + encodeURIComponent(item.token) + "/induct", args);
    }

    async uploadPhotoForRespondentInCurrentWorkspaceAsync(blob: Blob): Promise<INullResponse> {
        const data = await blobToBase64(blob);
        return this.putAsync("/workspaces/current/respondent/photo", {
            value: data
        });
    }
}

export default Api;